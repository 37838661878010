<template>
  <div v-show="loading" class="home">
    <van-popup v-model="show" @closed="closed" position="bottom" round :style="{ height: '60%' }">
      <div class="titlediv">
        <div class="cancel" @click="closed">取消</div>
        <div class="title">选择商家地址</div>
        <div class="sure" @click="sure">确定</div>
      </div>
      <div v-for="(item, index) in shopList" :key="index"
        :class="selectIndex == index ? 'SelectAddressItem' : 'addressItem'" @click="selectItem(index)">
        <div>
          <div style="display: flex; justify-content:space-between;">
            <div
              style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;width: 100%;">
              <span style="font-size: 1rem;font-weight: 600;"> {{ item.shop_name }}</span>
              <span style="font-size: 1rem;margin-left: 1rem;"> {{ getKm(item.gap) }}</span>
            </div>
          </div>
          <div style="margin-top: 0.5rem;margin-bottom: 0.7rem;margin-right: 2rem;"> <span>{{ item.address }}</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
export default {
  name: "",
  props: {
    shopList: {
      type: Array,
    },
  },
  data() {
    return {
      selectIndex: -1,
      show: true,
      loading: true,
      value: 1,
    };
  },
  created() {
    this.loading = true
  },

  components: {},
  methods: {
    getKm(gap) {
      if (!gap || gap === '0.0') {
        return ''
      }
      if (gap > 1000) {
        return '>1000km'
      } else {
        return gap + 'km'
      }
    },
    closed() {
      this.$emit("closed", false);
    },
    // 步进器事件
    change(value) {
      this.value = value;
    },
    // 购买
    buy() {
      // console.log(this.good);
      // 添加一个count属性
      if (localStorage.getItem("nickname")) {
        // this.$set(this.good, "count", this.value);
        let obj = {
          index: this.selectIndex,
          cont: this.value
        }
        this.$emit('seleceItem', obj)
      } else {
        Dialog.confirm({
          title: "未登录",
          message: "是否前去登陆",
        })
          .then(() => {
            this.$router.push("/Login");
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    getPhone(phone) {
      return this.$utils.formPhone(phone);
    },
    selectItem(index) {
      this.selectIndex = index
      // this.$emit('seleceShopItem', item)
    },
    sure() {
      if (this.selectIndex > -1) {
        let item = this.shopList[this.selectIndex]
        this.$emit('seleceShopItem', item)
      } else {
        Toast('请先选择商家地址')
      }
    }
  },
  mounted() {

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.titlediv {
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
  margin-top: 0.5rem;

  .cancel {
    margin-left: 1rem;
    color: #666;
    padding-top: 0.2rem;
  }

  .title {
    font-size: 1.1rem;
  }

  .sure {
    margin-right: 1rem;
    padding-top: 0.2rem;
    color: #576b95;
  }
}

.SelectAddressItem {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: white;
  padding: 0.5rem 0.8rem 0 0.8rem;
  border: 1px solid #ea1831;
}

.addressItem {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: white;
  padding: 0.5rem 0.8rem 0 0.8rem;
  border-bottom: 1px solid #c3c0c0;
}


.default {
  border-radius: 0.2rem;
  font-size: 0.8rem;
  background-color: red;
  color: white;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}
</style>